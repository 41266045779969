import {
  APP_INITIALIZER,
  EnvironmentProviders,
  Inject,
  makeEnvironmentProviders,
  ɵgetOriginalError
} from '@angular/core';
import { Router } from '@angular/router';
import { BCF_APP_ID, BCF_PRODUCTION_MODE, BCF_RELEASE_VERSION } from '@bcf-v2-configurators/tokens/app/token';
import { BcfAppId, BcfReleaseVersion } from '@bcf-v2-configurators/tokens/app/types';
import { WINDOW } from '@bcf-v2-platforms/platform-apis/window-provider';
import { WorkerSynchronizer, WorkerSynchronizerSpawner } from '@bcf-v2-platforms/platform-worker/worker-synchronizer';
import { Cookies } from '@bcf-v2-utilities/cookies-provider/cookies';
import { distinctUntilChanged, map, startWith } from 'rxjs';
import { prepareUnhandledError } from '../common/prepare-unhandled-error';
import { sendToErrorReporting, sendToErrorUnhandledReporting } from '../common/send-to-error-reporting';

export function provideGcpErrorReportingBrowser(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,

      useFactory:
        (
          bcfProductionMode: boolean,
          bcfAppId: BcfAppId,
          bcfReleaseVersion: BcfReleaseVersion,
          window: Window,
          router: Router,
          cookies: Cookies,
          spawner: WorkerSynchronizerSpawner
        ) =>
        () => {
          // if (!bcfProductionMode) {
          //   return;
          // }
          const urlChannel: WorkerSynchronizer<string> = spawner.spawn<string>('gcpErrorReportingUrl');
          let currentUrl: string | undefined = undefined;

          router.events
            .pipe(
              map(() => router.url),
              startWith(router.url),
              distinctUntilChanged()
            )
            .subscribe((url: string) => {
              currentUrl = url;
              urlChannel.next(url);
            });

          window.addEventListener('unhandledrejection', (error: PromiseRejectionEvent) => {
            sendToErrorUnhandledReporting(
              bcfAppId,
              bcfReleaseVersion,
              prepareUnhandledError(error),
              currentUrl,
              cookies.getEagerAll()
            );
          });

          window.addEventListener('error', (error: ErrorEvent) => {
            if (error.error) {
              const errorToSend: Error | undefined = ɵgetOriginalError(error.error) ?? error.error;
              sendToErrorReporting(bcfAppId, bcfReleaseVersion, errorToSend, currentUrl, cookies.getEagerAll());
            }
          });
        },
      deps: [
        [new Inject(BCF_PRODUCTION_MODE)],
        [new Inject(BCF_APP_ID)],
        [new Inject(BCF_RELEASE_VERSION)],
        [new Inject(WINDOW)],
        Router,
        Cookies,
        WorkerSynchronizerSpawner
      ],
      multi: true
    }
  ]);
}
